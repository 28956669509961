import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import {
  ImageDetailPending,
  ImageDetailPendingDeleteManyRequest,
  ImageDetailPendingMergeRequest,
  ImageDetailPendingResetManyRequest,
  ImageDetailPendingSaveAndStoreManyRequest,
  ImageDetailPendingSearchResponse
} from '../models/ImageDetailPending';
import { ImageDetailPendingSearch } from '@/models/ImageDetailPendingSearch';
import { StartupService } from '@/startup.service';

@Injectable({
  providedIn: 'root'
})
export class ImageDetailPendingService {
  shipmentApiBaseUrl: string;
  shipmentApiHeadersJson: HttpHeaders;
  constructor(
    private httpClient: HttpClient,
    private _startupService: StartupService,
    private authService: AuthService
  ) {
    this.shipmentApiBaseUrl = this._startupService.shipmentApiUrl;

    this.shipmentApiHeadersJson = new HttpHeaders({
      EnterpriseID:
        this.authService.BlueShipUser &&
          this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,

      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID
          ? this.authService.BlueShipUser.userID.toString()
          : null,

      UserName: this.authService.BlueShipUser
        ? this.authService.BlueShipUser.userName
        : null
    });
  }

  deleteImageDetailPending(imageDetailPendingID: number): Observable<object> {
    return this.httpClient.delete(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}`,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  deleteManyImageDetailPending(
    imageDetailPendingIDs: number[]
  ): Observable<object> {
    const request = {
      imageDetailPendingIDs: imageDetailPendingIDs
    } as ImageDetailPendingDeleteManyRequest;

    return this.httpClient.post(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/deleteMany`,
      request,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  getInvoiceImagesByRefOrPro(primaryReference: string, pro: string): Observable<ImageDetailPendingSearch> {
    var url = `${this._startupService.shipmentApiUrl}v3/imagedetailpending/search?` +
      `offsetRows=0&fetchRows=100&sortColumn=createdDate&sortDirection=desc&proOrRef=true`;

    url += (primaryReference) ? `&primaryReference=${primaryReference}` : '';
    url += (pro) ? `&pro=${pro}` : '';

    return this.httpClient.get<ImageDetailPendingSearch>(url, {
      headers: this.shipmentApiHeadersJson,
    });
  }

  getImageDetailPendingById(
    imageDetailPendingID: number
  ): Observable<ImageDetailPending> {
    return this.httpClient.get<ImageDetailPending>(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}`,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  getBlobImageDetailPendingById(imageDetailPendingID: number): Observable<any> {
    return this.httpClient.get(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}/image`,
      {
        headers: this.shipmentApiHeadersJson,
        responseType: 'blob' as 'json'
      }
    );
  }

  getPdfBlobImageDetailPendingById(
    imageDetailPendingID: number
  ): Observable<any> {
    return this.httpClient.get(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}/imageAsPdf`,
      {
        headers: this.shipmentApiHeadersJson,
        responseType: 'blob' as 'json'
      }
    );
  }

  searchImageDetail(
    offsetCount: number,
    fetchCount: number,
    sortColumn: string,
    sortDirection: string,
    isError: boolean,
    isSaveAndStore: boolean,
    pro: string | null,
    primaryReference: string | null
  ): Observable<ImageDetailPendingSearchResponse> {
    return this.httpClient.get<ImageDetailPendingSearchResponse>(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/search` +
      `?offsetRows=${offsetCount}&fetchRows=${fetchCount}` +
      `&sortColumn=${sortColumn}&sortDirection=${sortDirection}` +
      `&isError=${isError}&isSaveAndStore=${isSaveAndStore}` +
      `&pro=${pro ? pro : ''}&primaryReference=${primaryReference ? primaryReference : ''}`,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  resetImageDetailPending(imageDetailPendingID: number): Observable<object> {
    return this.httpClient.post(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}/reset`,
      null,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  resetManyImageDetailPending(
    imageDetailPendingIDs: number[]
  ): Observable<object> {
    const request = {
      imageDetailPendingIDs: imageDetailPendingIDs
    } as ImageDetailPendingResetManyRequest;

    return this.httpClient.post(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/resetMany`,
      request,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  createImageDetailPending(
    imageDetailPending: ImageDetailPending
  ): Observable<ImageDetailPending> {
    return this.httpClient.post<ImageDetailPending>(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/`,
      imageDetailPending,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  /**
   * Updates an `ImageDetailPending` record by replacing it. Including the Image binary in the `image` property.
   *
   * @return an `Observable` of the empty body as an `Object`.
   */
  updateImageDetailPending(
    imageDetailPendingID: number,
    fullImageDetailPending: ImageDetailPending
  ): Observable<object> {
    return this.httpClient.put(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}`,
      fullImageDetailPending,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  /**
   * Updates an `ImageDetailPending` record by merging the input with the existing record in the database.
   * Useful when not wanting to replace the `image` property.
   *
   * @return an `Observable` of the empty body as an `Object`.
   */
  mergeImageDetailPending(
    imageDetailPendingID: number,
    partialImageDetailPending: ImageDetailPendingMergeRequest
  ): Observable<object> {
    return this.httpClient.patch(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/${imageDetailPendingID}`,
      partialImageDetailPending,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }

  turnOnSaveAndStoreManyImageDetailPending(
    imageDetailPendingIDs: number[]
  ): Observable<object> {
    const request = {
      imageDetailPendingIDs: imageDetailPendingIDs
    } as ImageDetailPendingSaveAndStoreManyRequest;

    return this.httpClient.post(
      `${this.shipmentApiBaseUrl}v3/ImageDetailPending/saveAndStoreMany`,
      request,
      {
        headers: this.shipmentApiHeadersJson
      }
    );
  }
}
