import { AuthService } from '@/auth/auth.service';
import { GridState } from '@/services/AuditQueue';
import { EnterpriseChildDTO, IUpdateCustomerPendingInvoice } from '@/services/Collection';
import { CustomerService } from '@/services/customer.service';
import { MatchPendingInvoiceManualBOL, PendingCarrierInvoice } from '@/services/Invoice';
import { InvoiceService } from '@/services/invoice.service';
import { ShipmentSearchDTO } from '@/services/Shipment';
import { ShipmentService } from '@/services/shipment.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Helpers } from '@/_shared/helpers';
import { ModalComponent } from '@/_shared/modal/modal.component';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStateChangeEvent, FilterService, GridComponent, GridDataResult, PageChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { concatMap, map } from 'rxjs/operators';
import { StartupService } from '@/startup.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ConvertToShipmentDialogComponent } from '../convert-to-shipment-dialog/convert-to-shipment-dialog.component';
import { ConvertJSONToCSVHelper } from '@/models/invoice-reports.interface';
import { FormatDataExportExcel } from '@/pages/duplicate-carrier/duplicate-carrier.interface';
import { AddNoteDialogComponent } from '../add-note-dialog/add-note-dialog.component';
import { ImageDetailPendingSearch } from '@/models/ImageDetailPendingSearch';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { ImageDetailPendingService } from '@/services/image-detail-pending.service';
import { ImageDetailPending } from '@/models/ImageDetailPending';
import { LaunchDarklyFlagNames, LaunchDarklyService } from '@/services/launch-darkly/launch-darkly.service';
import { ISubscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-invoices-unmatched-list',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './invoices-unmatched-list.component.html',
  styleUrls: ['./invoices-unmatched-list.component.scss'],
  host: {
    class: 'invoice-unmatched-list'
  }
})
export class InvoicesUnmatchedListComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;
  @ViewChild(ModalComponent, { static: true })
  modalAssign: ModalComponent;

  @ViewChild('theGrid', { static: false })
  private recordGrid: GridComponent;

  _theRecords: PendingCarrierInvoice[];
  get theRecords(): PendingCarrierInvoice[] {
    return this._theRecords;
  }

  @Input('theRecords')
  set theRecords(value: PendingCarrierInvoice[]) {
    this._theRecords = value || [];
    this.recordBookGrid = this._theRecords;
    this.setRecords();
    this.managementExcelFormat();
  }

  @Input('loading')
  loading = false;

  @Output()
  reset = new EventEmitter<PendingCarrierInvoice[]>();

  @Output()
  resubmit = new EventEmitter<PendingCarrierInvoice>();

  @Output()
  pickref = new EventEmitter<MatchPendingInvoiceManualBOL>();

  @Output('deleteInvoice')
  deleteInvoice = new EventEmitter<PendingCarrierInvoice>();

  @Output('filtered')
  filtered = new EventEmitter<PendingCarrierInvoice[]>();

  @Output() invoiceAssign = new EventEmitter<boolean>();

  @Output() flagInvoice = new EventEmitter<boolean>();

  @Output() refreshTable = new EventEmitter();

  gridView: GridDataResult;
  helpers: Helpers;
  skip = 0;
  pageSize = 25;
  openedMultiSelect: boolean;

  // Carrier Images
  showImageDetailPendingList: boolean = false;
  displayViewImageDialog: boolean = false;
  imageDialogDisplayText: string;
  imageResults: ImageDetailPendingSearch;
  activeImageDetailPending: ImageDetailPending;
  showUnmatchedInvoiceImages: boolean = false;
  launchDarklyFlagChange$: ISubscription;

  openedPickRef: boolean;
  primaryReference: string;
  noPrimRefFound: boolean;
  recordBookGrid: PendingCarrierInvoice[] = [];
  allDataRecord: PendingCarrierInvoice[] = [];
  shipments: ShipmentSearchDTO[] = [];
  selectedShipment: ShipmentSearchDTO;
  selectedPendingCarrierInvoice: PendingCarrierInvoice;
  userSelectedPrimaryReference: string;
  gridState: DataStateChangeEvent = new GridState();
  assignedCustomer: string;
  convertJson: string = '';
  selectedPrimary = Array<any>();
  proListFilter = Array<any>();
  carrierListFilter = Array<any>();
  enterpriseData = Array<EnterpriseChildDTO>();
  enterprises: EnterpriseChildDTO;

  billtoListFilter = Array<any>();
  salesRepListFilter = Array<any>();
  primaryContactListFilter = Array<any>();
  departmentListFilter = Array<any>();
  departmentGroupListFilter = Array<any>();
  shipperListFilter = Array<any>();
  originCityListFilter = Array<any>();
  receiverListFilter = Array<any>();
  customerNameListFilter = Array<any>();
  customerAccountNumberListFilter = Array<any>();

  public selectableSettings: SelectableSettings;
  public selectableSettingsChooseShip: SelectableSettings;
  public selectedRecords: number[] = [];
  public selectedRecordsChooseShip: string[] = [];
  deletePendingCarrierInvoice: PendingCarrierInvoice = null;
  filter: string = null;
  invoiceDetails: PendingCarrierInvoice = null;
  dialogWidth: number;
  dialogHeight: number;
  enableConvert: boolean = false;
  invoiceForSuggestedShipments?: PendingCarrierInvoice;
  @Output() removeInvoice = new EventEmitter();
  errorMessageMatchShipmentBOL: string;


  public sort: SortDescriptor[] = [
    {
      field: 'DateCreated',
      dir: 'desc',
    },
  ];

  constructor(
    private shipmentService: ShipmentService,
    private invoiceService: InvoiceService,
    private authService: AuthService,
    private customerServices: CustomerService,
    private startupService: StartupService,
    private imageDetailPendingService: ImageDetailPendingService,
    private dialogService: DialogService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.setSelectableSettings();
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);
    this.openedMultiSelect = false;
    this.openedPickRef = false;
    this.selectedShipment = null;
    this.userSelectedPrimaryReference = null;
    this.selectedPendingCarrierInvoice = null;
    this.dialogHeight = window.innerHeight * 0.9;
    this.dialogWidth = window.innerWidth * 0.9;
    this.enableConvert = this.startupService.enableConvert;

    this.showUnmatchedInvoiceImages =
      this.launchDarklyService.flags[LaunchDarklyFlagNames.unmatchedInvoiceImages];
    this.launchDarklyFlagChange$ =
      this.launchDarklyService.flagChange.subscribe((flags) => {
        this.showUnmatchedInvoiceImages = flags[LaunchDarklyFlagNames.unmatchedInvoiceImages];
      });
  }

  @HostListener("window:resize", ['$event'])
  onResize(event) {
    this.dialogWidth = window.innerWidth * 0.9;
    this.dialogHeight = window.innerHeight * 0.9;
  }

  // disables checkbox if option is multi select
  public isDisabled(args) {
    return {
      'k-disabled': args.dataItem.multipleShipments === 1,
    };
  }

  // multiple or one selected
  public onResetInvoices() {
    const selectedResetInvoices = [];
    for (const record of this.selectedRecords) {
      const dataItem = this.recordBookGrid.filter((obj) => obj.id === record);
      selectedResetInvoices.push(dataItem[0]);
    }
    this.reset.emit(selectedResetInvoices);
  }

  public managementExcelFormat(): void {
    let data;
    if (this.recordBookGrid.length !== 0) {
      data = FormatDataExportExcel.managementFormt(this.recordBookGrid);
      this.convertJson = ConvertJSONToCSVHelper(data);
    }
  }


  public onRefreshTable(): void {
    this.refreshTable.emit();
  }

  public disableConvertToShipment() {
    let disabled = false;
    const records = this.recordBookGrid.filter(x => this.selectedRecords.indexOf(x.id) !== -1);
    for (const record of records) {
      disabled = disabled
        || !(record.customerName && record.customerName !== "")
        || !(record.itemTotal && record.itemTotal > 0);
    }

    return records.length == 0 || disabled;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.gridView = process(this.allDataRecord, this.gridState);

    state.filter.filters.forEach((info: any) => {
      info.filters.forEach((data) => {
        if (data.field == "invoiceDate")
          data.value = !data.value ? Helpers.stringToDate(data.value).toString() : data.value;
      })
    });
    localStorage.setItem('filters', JSON.stringify(state));
  }

  public filterChange(values: any[], filterService: FilterService, title: string): void {
    Helpers.filtersBySelectAudit(values, filterService, title);
  }

  private makeListFilters(records: PendingCarrierInvoice[]): void {
    this.billtoListFilter = Helpers.makeListFiltersAudit(records, this.billtoListFilter, 'BilToFull');
    this.salesRepListFilter = Helpers.makeListFiltersAudit(records, this.salesRepListFilter, 'salesRep');
    this.primaryContactListFilter = Helpers.makeListFiltersAudit(records, this.primaryContactListFilter, 'primaryContact');
    this.departmentListFilter = Helpers.makeListFiltersAudit(records, this.departmentListFilter, 'department');
    this.departmentGroupListFilter = Helpers.makeListFiltersAudit(records, this.departmentGroupListFilter, 'departmentGroup');
    this.shipperListFilter = Helpers.makeListFiltersAudit(records, this.billtoListFilter, 'shipper');
    this.originCityListFilter = Helpers.makeListFiltersAudit(records, this.billtoListFilter, 'originCity');
    this.proListFilter = Helpers.makeListFiltersAudit(records, this.proListFilter, "pro");
    this.carrierListFilter = Helpers.makeListFiltersAudit(records, this.carrierListFilter, "carrierName");
    this.receiverListFilter = Helpers.makeListFiltersAudit(records, this.receiverListFilter, "receiver");
    this.customerNameListFilter = Helpers.makeListFiltersAudit(records, this.customerNameListFilter, "customerName");
    this.customerAccountNumberListFilter = Helpers.makeListFiltersAudit(records, this.customerAccountNumberListFilter, "customerAccountNumber");
  }

  // direct on row click
  public resetInvoice(dataItem) {
    const selectedResetInvoices = [];
    selectedResetInvoices.push(dataItem);
    // send the message
    this.reset.emit(selectedResetInvoices);
  }
  public pickRefSelected() {
    this.errorMessageMatchShipmentBOL = 'No shipment found for the entered reference.';
    let shipments: ShipmentSearchDTO[] = null;
    this.shipmentService.findShipments(this.userSelectedPrimaryReference, '').subscribe(
      async (data) => {
        shipments = data;
        if (shipments == null || shipments.length === 0) {
          this.noPrimRefFound = true;
          return;
        }

        // we are only interested in Primary matches
        shipments = shipments.filter((obj) => obj.type === 'BOL' && obj.isPrimary.toLowerCase() === 'true');

        for (let index = 0; index < shipments.length; index++) {
          const element = shipments[index];
          var result = await this.shipmentService.getShipmentByID(element.shipmentID).toPromise();
          if (result.billingAddress.paymentMethod.toLowerCase() === "collect") {
            shipments = shipments.filter((obj) => obj.shipmentID != result.id);

            if (shipments.length === 0) {
              this.errorMessageMatchShipmentBOL = 'Unable to match to Collect Shipment.';
            }
          }
        }

        if (shipments.length === 0) {
          this.noPrimRefFound = true;
          return;
        }

        // we should have at least two shipments if we are going to display the popup
        if (shipments != null && shipments.length > 1) {
          this.openedPickRef = false;
          // this is used in dialog title and just looks better
          this.selectedPendingCarrierInvoice.primaryReference = this.userSelectedPrimaryReference;
          this.showShipments(shipments);
          this.userSelectedPrimaryReference = null;
        } else {
          // send the message to parent
          this.pickref.emit({
            id: this.selectedPendingCarrierInvoice.id,
            primaryReference: this.userSelectedPrimaryReference,
          } as MatchPendingInvoiceManualBOL);

          this.openedPickRef = false;
          this.userSelectedPrimaryReference = null;
        }
      },
      (error) => console.log(error)
    );
  }

  public resubmitInvoice(dataItem) {
    // send the message
    this.resubmit.emit(dataItem);
  }

  // removes all records successully reset/resubmitted
  removeRecords(recordList: number[]) {
    for (let t = 0; t < recordList.length; t++) {
      this.theRecords.splice(
        this.theRecords.findIndex((i) => i.id === recordList[t]),
        1
      );

      const index = this.recordBookGrid.findIndex((i) => i.id === recordList[t]);
      if (index > -1) {
        this.recordBookGrid.splice(index, 1);
      }

      // remove the selected items
      if (this.selectedRecords.length > 0) {
        this.selectedRecords = this.selectedRecords.filter((obj) => obj !== recordList[t]);
      }
    }
    this.skip = 0;
    this.setRecords();
  }

  public applyFilter(): void {
    const search = this.filter || null;
    this.searchFilter(search);
  }

  protected searchFilter(search: string = null): void {
    if (search) {
      this.recordBookGrid = this.theRecords.filter((obj) => this.filterDelegate(obj, search));
    } else {
      this.recordBookGrid = this.theRecords;
    }
    this.pageChange({ skip: 0, take: this.pageSize });
    this.filtered.emit(this.recordBookGrid);
  }

  protected onViewInvoice(dataItem: any) {
    this.invoiceService.getInvoiceByID(dataItem.id)
      .subscribe(x => {
        this.invoiceDetails = x;
      },
        err => {
          this.invoiceDetails = null;
        });
  }

  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }
  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
  }

  // dialogs
  public closeMultiSelect(status) {
    this.openedMultiSelect = false;
    if (status === 'select' && this.selectedShipment != null) {
      // resubmit the invoice
      this.selectedPendingCarrierInvoice.shipmentID = this.selectedShipment.shipmentID;
      this.resubmitInvoice(this.selectedPendingCarrierInvoice);
    }

    // reset the varaibles
    this.clearVariables();
  }

  public closePickRef() {
    this.openedPickRef = false;
    // reset the varaibles
    this.clearVariables();
  }

  public openImageListDialog(invoice: PendingCarrierInvoice) {
    let primaryReference = invoice.primaryReference;
    let pro = invoice.pro;
    this.selectedPendingCarrierInvoice = invoice;
    this.showImageDetailPendingList = true;
    this.imageDialogDisplayText = '';

    // get the images
    this.getInvoiceImagesByRefOrPro(primaryReference, pro);
  }

  public openViewImageDialog(image: ImageDetailPending) {
    this.displayViewImageDialog = true;
    this.activeImageDetailPending = image;
  }

  public closeImageListDialog() {
    this.showImageDetailPendingList = false;
    this.clearVariables();
  }

  public onCloseViewImageDialog() {
    this.displayViewImageDialog = false;
    this.activeImageDetailPending = null;
  }

  public onDialogImageRecordUpdated(deleted = false) {
    let primaryReference = this.selectedPendingCarrierInvoice.primaryReference;
    let pro = this.selectedPendingCarrierInvoice.pro;
    this.getInvoiceImagesByRefOrPro(primaryReference, pro, deleted);
  }

  public onEditNextImage() {
    let index = this.imageResults.imageDetailPendings.findIndex(x => x.imageDetailPendingID == this.activeImageDetailPending.imageDetailPendingID);

    if (index < this.imageResults.imageDetailPendings.length - 1) {
      this.activeImageDetailPending = this.imageResults.imageDetailPendings[index + 1];
    }
  }

  public onDeleteNextImage(index: number) {
    if (this.imageResults.imageDetailPendings.length > 0) {
      this.activeImageDetailPending = (index <= this.imageResults.imageDetailPendings.length - 1)
        ? this.imageResults.imageDetailPendings[index]
        : this.imageResults.imageDetailPendings[index - 1];
    } else {
      this.activeImageDetailPending = null;
      this.onCloseViewImageDialog();
    }
  }

  // TODO - This need to be refactored using ForkJoin or something simuliar
  public openMultiSelect(obj: PendingCarrierInvoice) {
    this.selectedPendingCarrierInvoice = obj;

    // get the full record for this pending record
    this.invoiceService.getInvoiceByID(this.selectedPendingCarrierInvoice.id).subscribe(
      (invrecord) => {
        // first make sure there are references
        if (invrecord.references == null || invrecord.references.length === 0) {
          return;
        }

        // ensure there is a primary
        if (invrecord.references.find((obj2) => obj2.isPrimary === true) == null) {
          return;
        }

        // find shipment based on 'whatever' isprimary is
        let shipments: ShipmentSearchDTO[] = null;
        const primaryReference = invrecord.references.find((obj2) => obj2.isPrimary === true);
        const proReference = invrecord.references.find((x) => x.type === 'PRO');
        const invoiceNumber = invrecord.invoiceNumber;
        // find it based on value and type
        if (primaryReference !== null) {
          this.shipmentService.findShipments(primaryReference.value, primaryReference.type).subscribe(
            (data) => {
              shipments = data;
              if (shipments.length <= 1) {
                // try this again with no type
                this.shipmentService.findShipments(primaryReference.value, '').subscribe(
                  (data2) => {
                    shipments = data2;
                    // try on pro
                    if (shipments.length <= 1 && proReference != null) {
                      this.shipmentService.findShipments(proReference.value, proReference.type).subscribe(
                        (data3) => {
                          shipments = data3;
                          if (shipments.length <= 1 && invoiceNumber != null) {
                            // last try on Invoice # as PRO
                            this.shipmentService.findShipments(invoiceNumber, 'PRO').subscribe((data4) => {
                              shipments = data4;
                              if (shipments.length <= 1) {
                                this.alertMessage.showAlertMessage('Unable to obtain shipments for this invoice.', 'Error');
                              } else {
                                // we should have at least two shipments if we are going to display the popup
                                this.showShipments(shipments);
                              }
                            });
                          } else if (shipments.length <= 1) {
                            this.alertMessage.showAlertMessage('Unable to obtain shipments for this invoice.', 'Error');
                          } else {
                            this.showShipments(shipments);
                          }
                        },
                        (error) => console.log(error)
                      );
                      // or try invoice # as pro
                    } else if (shipments.length <= 1 && invoiceNumber != null) {
                      this.shipmentService.findShipments(invoiceNumber, 'PRO').subscribe(
                        (data5) => {
                          shipments = data5;
                          if (shipments.length <= 1) {
                            this.alertMessage.showAlertMessage('Unable to obtain shipments for this invoice.', 'Error');
                          } else {
                            this.showShipments(shipments);
                          }
                        },
                        (error) => console.log(error)
                      );
                      // no more option show error
                    } else if (shipments.length <= 1) {
                      this.alertMessage.showAlertMessage('Unable to obtain shipments for this invoice.', 'Error');
                    } else {
                      this.showShipments(shipments);
                    }
                  },
                  (error) => console.log(error)
                );
              } else {
                this.showShipments(shipments);
              }
            },
            (error) => console.log(error)
          );
        } else {
          // no primary reference we don't start searching
          this.alertMessage.showAlertMessage('Unable to obtain shipments for this invoice.', 'Error');
        }
      },
      (error) => console.log(error)
    );
  }

  public pickRefInvoice(obj: PendingCarrierInvoice) {
    this.selectedPendingCarrierInvoice = obj;
    this.openedPickRef = true;
  }
  public showShipments(shipments: ShipmentSearchDTO[]) {
    this.shipments = shipments;
    this.openedMultiSelect = true;
  }

  public onChooseShipmentChange() {
    if (this.selectedRecordsChooseShip.length === 0) {
      this.selectedShipment = null;
    } else {
      const intShip = parseInt(this.selectedRecordsChooseShip[0], 10);
      this.selectedShipment = Object.assign(
        {},
        this.selectedShipment,
        this.shipments.find((x) => x.shipmentID === intShip)
      );
    }
  }

  hasNotes(dataItem: any): boolean {
    return dataItem.noteCount > 0;
  }

  disableAddNote(): boolean {
    return this.selectedRecords.length <= 0;
  }

  onAddNote(): void {
    let ref = this.dialogService.open({
      title: "Add Note",
      content: AddNoteDialogComponent
    });

    let content = ref.content.instance as AddNoteDialogComponent;
    content.selectedRecords = this.selectedRecords;

    ref.result.subscribe((x: unknown) => {
      if (x instanceof DialogCloseResult) return;

      let results = x as any[];

      for (let i = 0; i < results.length; i++) {
        let invoice = this.allDataRecord.find(x => x.id == results[i].carrierInvoiceId);
        invoice.noteCount++;
      }

      for (let i = 0; i < this.theRecords.length; i++) {
        this.recordGrid.collapseRow(i);
      }

      this.selectedRecords = [];
    });
  }

  // helpers
  private clearVariables() {
    this.shipments = [];
    this.noPrimRefFound = false;
    this.selectedShipment = null;
    this.imageResults = null;
    this.selectedRecordsChooseShip = [];
    this.selectedPendingCarrierInvoice = null;
    this.userSelectedPrimaryReference = null;
  }

  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }

  private setRecords(): void {
    this.allDataRecord = orderBy(this.recordBookGrid, this.sort);

    this.allDataRecord.forEach((date: any) => {
      date.invoiceDate = Helpers.stringToDate(date.invoiceDate);
      date.BilToFull = date.billTo.name ? `${date.billTo.name}` : null;
      date.shipper = date.origin.name;
      date.originCity = date.origin.city ? `${date.origin.city} , ${date.origin.zip}` : null;
      date.receiver = date.destination.name;
      date.originDisplayName = `${date.origin.city}, ${date.origin.state} ${date.origin.zip}`;
      date.destinationDisplayName = `${date.destination.city}, ${date.destination.state} ${date.destination.zip}`;
    });

    this.gridView = {
      data: this.allDataRecord.slice(this.skip, this.skip + this.pageSize),
      total: this.allDataRecord.length,
    };

    let configFilters = JSON.parse(localStorage.getItem('filters'));

    if (configFilters) {
      this.gridState = configFilters;

      configFilters.filter.filters.forEach((info: any) => {
        info.filters.forEach((data) => {
          if (data.field == "invoiceDate") data.value = new Date(data.value);
        })
      });

      this.gridView = process(this.allDataRecord, configFilters);
    }

    this.makeListFilters(this.allDataRecord);
  }
  private filterDelegate(obj: PendingCarrierInvoice, search: string): boolean {
    search = search.toLowerCase();
    return (
      (obj.primaryReference != null && obj.primaryReference.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.pro != null && obj.pro.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.invoiceNumber != null && obj.invoiceNumber.toString().toLowerCase().indexOf(search) > -1)
    );
  }

  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
    };

    this.selectableSettingsChooseShip = {
      checkboxOnly: false,
      mode: 'single',
    };
  }

  openModalAssignOwner(): void {
    this.assignedCustomer = '';
    const optionsModal = {
      width: 650,
      height: 200,
      title: 'Assign Owner'
    }
    this.modalAssign.showConfirmation(optionsModal);
  }


  public getEnterprise(filter): void {
    this.customerServices
      .getEnterprises(filter)
      .pipe(
        map((data) => {
          return data.map((row) => {
            row.fullName = `${row.name} - ${row.accountNumber}`;
            return row;
          });
        })
      )
      .subscribe((enterprise) => {
        enterprise.sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.enterpriseData = enterprise;
      });
  }

  public assignOwnerSave(): void {
    const selectedCustomer = this.enterpriseData.find(x => x.fullName === this.assignedCustomer);
    const dataUpdateInvoices: IUpdateCustomerPendingInvoice = {
      pendingInvoiceIDs: this.selectedRecords,
      customerName: selectedCustomer.name,
      customerAccountNumber: selectedCustomer.accountNumber,
      enterpriseId: selectedCustomer.enterpriseID
    };

    this.customerServices.updatePendingInvoices(dataUpdateInvoices).subscribe(() => {
      this.selectedRecords = [];
      this.invoiceAssign.emit(true);
      this.modalAssign.closeModal();
      this.alertMessage.showAlertMessage('Correctly assigned owner', 'succesfull');
    }, () => this.alertMessage.showAlertMessage('error when signing owner', 'error'));
  }

  public enterpriseFilterChange(filter: string): void {
    filter.trim();
    (filter.length >= 3) ? this.getEnterprise(filter) : this.enterprises = null;
    this.enterpriseData = [];
  }

  downloadExportData(csvPreppedData: string) {
    if (csvPreppedData && csvPreppedData.length > 0) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + escape(csvPreppedData));
      const fileName = 'Export-All-Unmatched-Invoice-Management' + new Date().getDate();
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  protected requestDeletePendingInvoice(e: MouseEvent, invoice: PendingCarrierInvoice): void {
    e.preventDefault();
    this.deletePendingCarrierInvoice = invoice;
  }

  protected confirmDeletePendingInvoice(e: MouseEvent) {
    e.preventDefault();
    const invoice = Object.assign({}, this.deletePendingCarrierInvoice);
    this.deleteInvoice.emit(invoice);
    this.deletePendingCarrierInvoice = null;
  }

  protected cancelDeletePendingInvoice(e: MouseEvent) {
    e.preventDefault();
    this.deletePendingCarrierInvoice = null;
  }

  protected closeInvoiceDetails(): void {
    this.invoiceDetails = null;
  }

  showSuggestedShipments(invoice: PendingCarrierInvoice) {
    this.invoiceService.getReferences(invoice.id).pipe(
      concatMap((references) => {
        invoice.references = references;
        return this.invoiceService.getCarrierInvoiceItems(invoice.id);
      })
    ).subscribe((items) => {
      invoice.items = items;
      this.invoiceForSuggestedShipments = invoice;
    });
  }

  onShipmentMatch(payload: { invoice: PendingCarrierInvoice, matchedPrimaryReference: string }) {
    this.closeSuggestedShipmentsModal();
    this.alertMessage.showAlertMessage(`Successfully matched invoice # ${payload.invoice.invoiceNumber} to shipment ${payload.matchedPrimaryReference}`, 'Success');

    // matching is an async process so we filter out the matched invoice on the client
    this.removeInvoice.emit(payload.invoice);
  }

  closeSuggestedShipmentsModal() {
    this.invoiceForSuggestedShipments = undefined;
  }

  private onEditItems(id: number): void {
    window.open(`/invoices/unmatched/${id}`, '_blank');
  }

  private onConvertToShipment(): void {
    if (this.selectedRecords.length > 0) {
      const ref: DialogRef = this.dialogService.open({
        title: 'Convert to Shipment',
        content: ConvertToShipmentDialogComponent
      });

      let contents = ref.content.instance as ConvertToShipmentDialogComponent;
      contents.invoiceIds = this.selectedRecords;
      contents.userCreated = this.authService.BlueShipUser.userName;

      ref.result.subscribe(result => {
        if (result instanceof DialogCloseResult) return;

        this.selectedRecords = [];
        this.invoiceAssign.emit(true);
      });
    }
  }

  private onFlagInvoice(): void {
    this.invoiceService
      .flagCarrierInvoice(this.selectedRecords)
      .subscribe(() => {
        this.flagInvoice.emit(true);
        this.selectedRecords = []
      });
  }

  private onUnflagInvoice(): void {
    this.invoiceService
      .unflagCarrierInvoice(this.selectedRecords)
      .subscribe(() => {
        this.invoiceAssign.emit(true);
        this.selectedRecords = [];
      });
  }

  private disableFlagInvoice(): boolean {
    return this.selectedRecords.length == 0 ||
      this.theRecords
        .filter(x => this.selectedRecords.indexOf(x.id) != -1)
        .filter(x => !x.customerName || x.itemTotal == 0).length > 0;
  }

  private disableUnflagInvoice(): boolean {
    return this.selectedRecords.length == 0;
  }

  /*
   * Returns an array of unique flag statuses
   */
  private flagStatusList(): string[] {
    return this.theRecords
      .map(x => x.flagStatus)
      .filter((x, i, self) => self.indexOf(x) === i);
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }

  private getInvoiceImagesByRefOrPro(primaryReference: string, pro: string, deleted = false) {
    let deletedIndex = deleted ? this.imageResults.imageDetailPendings.findIndex(x => x.imageDetailPendingID == this.activeImageDetailPending.imageDetailPendingID) : -1;

    this.imageDetailPendingService.getInvoiceImagesByRefOrPro(primaryReference, pro).subscribe(
      (data) => {
        this.imageResults = data;
        let primaryReference: string;
        let pro: string;

        this.imageResults.imageDetailPendings.forEach((idp) => {
          primaryReference = (!primaryReference && idp.primaryReference && idp.primaryReference.length > 0) ? idp.primaryReference : primaryReference;
          pro = (!pro && idp.pro && idp.pro.length > 0) ? idp.pro : pro;
        });

        this.imageDialogDisplayText = primaryReference ? ` by Primary Reference: ${primaryReference}` : '';
        let proPrefix = this.imageDialogDisplayText.length > 0 ? `${this.imageDialogDisplayText}, and` : '';
        this.imageDialogDisplayText = pro ? `${proPrefix} by PRO: ${pro}` : this.imageDialogDisplayText;

        if (deleted) {
          this.onDeleteNextImage(deletedIndex);
        }
      },
      (error) => {
        this.closeImageListDialog();
        this.handleError(error);
      }
    );
  }
}
